.sn_intro_slider_video {
  color: $white;


	.sn_item_in{
		position: relative;

		.video {
			height: 100vh;
			width: 100%;

			.plyr {
				height: 100%;

				.plyr__video-wrapper {
					object-fit: cover;
					min-height: 100%;
					min-width: 100%;
					width: auto;
					height: auto;
					left: 50%;
					top: 50%;
					transform: translate3D(-50%, -50%, 0);
				}
			}
		}

    figure {
      height: 100vh;
  		background-size: cover;
  		background-position: center center;
  	}

    .content{
  		position: absolute;
      bottom: 40px;
			width: 100%;
    }
  }

	.sn_swiper_pagination {
		position: absolute;
		bottom: 40px !important;;
		text-align: right;
		margin-top: 0px;
		display: block;
		max-width: 100%;
		right: 0;

		@include mq(md) {
			max-width: 50%;
		}

		.swiper-pagination-bullet{
			padding: 12px;
		}
	}
}
