.sn_gallery {
  color: $gray-900;

  @include mq(lg) {
    color: $white;
  }

  .sn_item_in{
    position: relative;

    figure {

			@include mq(0px, md-max) {
				background: none !important;
			}

			@include mq(lg) {
				height: 100vh;
				background-size: cover;
				background-position: center center;
			}

			img {
				display: block;
				width: 100%;

				@include mq(lg) {
					display: none;
				}
			}
  	}

    .content{
      position: relative;
      bottom: 0px;
      width: 100%;

      @include mq(lg) {
        position: absolute;
        bottom: 40px;
      }

      .wrapper{
        min-height: auto;
        background: $white;

        @include mq(lg) {
          background: none;
        }

        @include mq(lg) {
          min-height: 250px;
        }
      }
    }
  }
}
