@mixin font-face($font-family, $file-path, $font-weight: normal, $font-style: normal, $exts: (eot woff2 woff ttf svg)){

	$src: '';
	@if index($exts, eot) { $src: #{$src + url('#{$file-path}.eot?#iefix') format('embedded-opentype') + ','}; }
	@if index($exts, woff2) { $src: #{$src + url('#{$file-path}.woff2') format('woff2') + ','}; }
	@if index($exts, woff) { $src: #{$src + url('#{$file-path}.woff') format('woff') + ','}; }
	@if index($exts, ttf) { $src: #{$src + url('#{$file-path}.ttf') format('truetype') + ','}; }
	@if index($exts, svg) { $src: #{$src + url('#{$file-path}.svg##{$font-family}') format('svg') + ','}; }
	@if $src {$src: #{str-slice($src, 1, -2)} }

	@font-face{
		font-family: $font-family;

		@if index($exts, eot) { src: url('#{$file-path}.eot'); }
		src: $src;
		font-weight: $font-weight;
		font-style: $font-style;
	}

	// Chrome for Windows rendering fix: http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
	@if index($exts, svg) {
		@media screen and (-webkit-min-device-pixel-ratio: 0){
			@font-face{
				font-family: $font-family;
				src: url('#{$file-path}.svg##{$font-family}') format('svg');
			}
		}
	}
}

@mixin hamburger ($width: 30px, $height: $width, $bar-height: 2px) {
	/*
		Usage:

		HTML:
			<a class="hamburger">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</a>

		SCSS:
			.hamburger {
				@include hamburger($width: 23px, $height: 22px, $bar-height: 2px);

				&:hover,
				&:active {
					> span {
						background: $primary;
					}
				}

				> span {
					background: $body-color;
				}
			}
	*/

	display: block;
	width: $width;
	height: $height;
	transform: rotate3d(0, 0, 1, 0deg);
	transition: transform $transition-slow-easing;

	> span {
		width: $width;
		height: $bar-height;
		display: block;
		position: absolute;
		left: 0;
		transition:
			background $transition-base-easing,
			transform $transition-slow-easing;

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: (($height - $bar-height) / 2);
			transform: rotate3d(0, 0, 1, 0deg);
		}

		&:nth-child(4) {
			top: ($height - $bar-height);
		}

		&:nth-child(1),
		&:nth-child(4) {
			transform: rotate3d(0, 1, 0, 0deg);
		}
	}

	//

	// Class now needs to be on `body` element, or on the parent
	._open & {
		transform: rotate3d(0, 0, 1, 180deg);

		> span {
			&:nth-child(1),
			&:nth-child(4) {
				transform: rotate3d(0, 1, 0, 90deg);
			}

			&:nth-child(2) {
				transform: rotate3d(0, 0, 1, -45deg);
			}

			&:nth-child(3) {
				transform: rotate3d(0, 0, 1, 45deg);
			}
		}
	}
}

@mixin is-debug {
	body._debug & {
		@content;
	}
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder           { @content }
  &::-moz-placeholder          { @content }
  &:-ms-input-placeholder      { @content }
}
