.sn_banner_project{
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: $zindex-fixed;
	transition: $transition-base;
	transform: translateY(100%);
	opacity: 0;
	pointer-events: none;

	&._active {
		opacity: 1;
		pointer-events: auto;
		transform: none;
		bottom: 30px;
	}

	&_wrapper{
		background: $gray-900;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		padding: 0px 40px;
		position: relative;

		@include mq(sm){
			flex-direction: row;
		}

		@include mq(lg){
			padding: 0px 100px;
		}

		._close{
			position: absolute;
			top: 11px;
			right: 11px;
			fill: #fff;
			width: 15px;
			height: 15px;
			cursor: pointer;
		}

		._content{
			color: $secondary;
			@include font-size(22px);
		}

		._banner_btn{
    	padding: 11px 28px 9px;
			width: 100%;
			color: $secondary;
			border-color: $secondary;
			height: auto;

			@include mq(sm){
				width: auto;
			}

			@include mq(md){
				width: 264px;
			}


			&:hover{
				background: $secondary;
				color: $gray-900;
			}
		}
	}

}
