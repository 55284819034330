.btn {
	@include font-size(13px);
	@include letter-spacing(.33px);
	font-weight: fw(medium);
	line-height: 1.38;
	text-transform: uppercase;
	padding: 2px 15px 0;
	width: 100%;
	box-shadow: none !important;
	height: 60px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: left;

	@include mq(md) {
		width: auto;
	}

	@include mq($db-font) {
		padding: 2px 30px 0;
	}
}
