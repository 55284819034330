.sn_overlap_vertical {

	a {
		text-decoration: none;
	}

	[data-animation-offset] {
		opacity: 1 !important;
		transition: $transition-animation-offset;
	}

	&_title {
		margin-top: -60px;

		@include mq(md) {
			margin-top: pxvw(-170px);
		}

		@include mq(1920px) {
			margin-top: -170px;
		}
	}

	p {

		& + img {
			margin-top: 40px;
			max-width: 100% !important;
			width: auto !important;
	 }
	}

 .btn {
		@include mq(md) {
			min-width: 275px;
		}
 }

 iframe{
	 border: 0;
 }
}
