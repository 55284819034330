.sn_slider_text {
	overflow: hidden;


	&_content {
		z-index: 100;
	}

	&_slider {
		position: relative;
		z-index: 50;


		&::before {
			content: '';
			z-index: 70;
			width: 100vw;
			position: absolute;
			top: -5px;
			bottom: -5px;
			right: 100%;
			background-color: $body-bg;

			@include mq( 0px, lg) {
				background-color: transparent;
			}
		}

		&.bg-primary, &.bg-secondary, &.bg-dark,
		&.bg-light {
			&::before {
				background-color: inherit;
			}
		}

		.swiper-container {
			overflow: visible;
		}

		.sn_item_in_img {
			@include scut-ratio-box(553 / 828);
		}
	}

	.sn_swiper_pagination_ {
	  position: relative;
    z-index: 100;
    width: 100%;
    text-align: left;
    bottom: 0;
		margin: 14px -6px 0;

		@include mq(md) {
			margin: 14px -12px 0;
		}

    .swiper-pagination-bullet{
      cursor: pointer;
      font-size: 12px;
      line-height: 2.33;
      background: none !important;
      width: auto !important;
      height: auto !important;
      opacity: 0.8;
			padding: 6px;

      @include mq(md) {
				padding: 12px;
			}

      &:after{
        content: " ";
        height: 1px;
        width: 0px;
        background: currentColor;
        margin: 5px 0px;
        display: inline-block;
        transition: all 0.5s;
        transform: rotateX(90deg);
      }

      &.swiper-pagination-bullet-active{
        position: relative;
        opacity: 1;

        &:after{
          content: " ";
          height: 1px;
          width: 96px;
          background: currentColor;
          margin: 5px 12px 5px 24px;
          display: inline-block;
          transition: all 0.5s;
          transform: rotateX(0deg);
        }
      }
    }
  }
}
