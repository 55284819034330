// From Bootstrap
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;

	&:last-child {
		margin-bottom: 0;
	}
}

.display-1 {
	@include font-size(38px, pxvw(150px));
	@include line-height(1.21, 1.07);
	font-weight: fw(medium);
	color: $primary;

	@include mq(1920px) {
		font-size: 150px;
	}
}

h1,
.h1 {
	@include font-size(38px, pxvw(120px));
	@include line-height(1.21, 1);
	font-weight: fw(medium);
	color: $primary;

	@include mq(1920px) {
		font-size: 120px;
	}
}

h2,
.h2 {
	@include font-size(30px, 56px);
	@include line-height(1.4, 1.25);
	font-weight: fw(regular);
}

h3,
.h3 {
	@include font-size(24px, 34px);
	@include line-height(1.5, 1.35);
	font-weight: fw(regular);
}

h4,
.h4 {
	@include font-size(18px, 30px);
	font-weight: fw(regular);
}

h5,
.h5 {
	@include font-size(18px, 22px);
	@include line-height(1.67, 1.45);
	@include letter-spacing(.45px, .55px);
	font-weight: fw(medium);
}

h6,
.h6 {
	@include font-size(18px, 20px);
	@include line-height(1.67, 1.6);
	@include letter-spacing(.45px, .5px);
	font-weight: fw(regular);
}

p,
.p {
	@include font-size(16px);
	@include line-height(1.88);

	article & {
		margin-bottom: 30px;
	}

	&._small {
		@include font-size(14px);
		@include line-height(1.71);
	}
}

ul, ol {
	@include font-size(16px);
	@include line-height(1.88);
}

article {

	ul {
		list-style-type: none;
		padding: 0;

		li {
			margin-left: 30px;
			position: relative;
			margin-bottom: 1em;

			&:before {
				content: '';
				@include size(8px);
				display: inline-block;
				position: absolute;
				left: -30px;
				top: .625em;
				background: $primary;
				border-radius: 50%;
			}
		}
	}
}


ol {

}

small {

}

strong {
	font-weight: fw(heavy);
}

hr {

}
