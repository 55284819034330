.sn_intro_image {

	[data-animation-offset] {
		opacity: 1 !important;
		transition: $transition-animation-offset;
	}

	figure {
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
	}

	&_title {
		margin-top: -110px;

		@include mq(md) {
			margin-top: pxvw(-290px);
		}

		@include mq(1920px) {
			margin-top: -290px;
		}
	}

	&_pre_title {
		margin-top: -130px;

		@include mq(md) {
			margin-top: pxvw(-350px);
		}

		@include mq(1920px) {
			margin-top: -350px;
		}
	}
}
