.sn_contacts_info {

	.addresses {
		border-bottom: 1px solid $gray-900;
	}

	figure {
		height: 100%;
		background-size: cover;
		background-position: center center;

		@include mq(0px, md-max) {
			@include scut-ratio-box;
			max-width: 500px;
			margin: 0 auto;
		}
	}
}
