.sn_slider_image {

	overflow: hidden;

	&_slider {
		overflow: visible;
	}

	a{
		text-decoration: none;
	}

	&.bg-gray-600 {
		p, .h1, .h2, .h3, .h4, .h5, .h6,
		h1, h2, h3, h4, h5, h6, .sn_swiper_pagination {
			color: $white;

		}
	}

	.sn_item_img{
		@include scut-ratio-box(708/398);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center, center;
		display: block;
		width: 100%;
	}
	.sn_swiper_pagination{

		&._b_80{
			bottom: -80px !important;
		}

		.swiper-pagination-bullet{
			color:currentColor;
			opacity: 0.3;
			transition: $transition-base;

			&.swiper-pagination-bullet-active{
				opacity: 1;
			}
		}
	}

}
