.sn_intro_search {

	&_input {
		width: 100%;
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: 1px solid $gray-900;
		background: none;

		&:hover,
		&:focus {
			outline: none;
		}
	}

	i{
		width: 24px;
    height: 24px;
    position: absolute;
    margin-left: 24px;
	}
}
