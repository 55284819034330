.sn_author {
	&_subtitle {
		position: static;
		margin-bottom:60px;
		z-index: 10;

		@include mq(xl) {
			position: absolute;
		}

		a {
			text-decoration: none;
			position: relative;

			&::before {
				content:"";
				position: absolute;
				background: currentColor;
				height: 3px;
				width: 100%;
				bottom:-6px;
			}
		}
	}

	figure {
		width: 100%;
		max-width: 360px;
		@include scut-ratio-box(359/410);
		background-position: center, center;
		background-size: cover;
		background-repeat: no-repeat;
		bottom: 0;
	}

	&_vertical_title {
		margin-top: -55px;

		@include mq(md) {
			margin-top: pxvw(-40px);
		}

		@include mq(1920px) {
			margin-top: -40px;
		}
	}

	&_content {

		h5, .h5{
			font-weight: fw(regular);
		}

		@include mq(lg) {
			padding: 0px 40px;
		}
	}
}
