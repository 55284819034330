.sn_form {

  .form-group {
    margin-bottom: 70px;

    > label {
      @include font-size(12px);
      @include letter-spacing(.6px);
			font-weight: fw(medium);
      margin-bottom: 0;
    }

    .form-control {
      border-width: 0;
      border-bottom-width: 1px;
			color: $body-color;
			font-weight: fw(regular);
			box-shadow: none !important;

			&:focus {
				font-weight: fw(regular);
			}
    }

    textarea {

      &.form-control {
        margin-top: 40px;
				border-width: 1px;
        resize: none;
      }
    }
  }

	.custom-control {
		padding-left: 0;

		.custom-control-label {
			@include font-size(14px);
			line-height: 1.86;
			padding-left: 55px;

			&::before,
			&::after {
				top: 0;
				left: 0;
				box-shadow: none !important;
			}
		}
	}

	.is-invalid {

		& ~ label {

			a {
				color: inherit;

				&:hover {
					opacity: .5;
				}
			}
		}
	}
}
