.sn_collections {

	.sn_articles_wrapper_grid, .sn_articles_wrapper_inspiration {
		margin-top: 70px;
	}

	.sn_swiper_pagination  {
		display: none;
	}

	.swiper-container {
		.swiper-wrapper {
			.swiper-slide {
				&:not(.swiper-slide-active) {
					a {
						&, &::after {
							pointer-events: none;
						}
					}
				}
			}
		}
	}

	.sn_item_in, & .sn_product {

		a {
			text-decoration: none;

			@include mq(lg) {

				.link {
					@include font-size(0px);

					&::before  {
						display: none;
					}
				}
			}

			.link{
				text-decoration: none;
				display: inline;
				position: relative;
				color: inherit;

				&::before {
					content:"";
					position: absolute;
					background: currentColor;
					height: 3px;
					width: 100%;
					bottom:-6px;
				}
			}
		}
	}

	@include mq(lg){

		.sn_swiper_pagination  {
			display: block;
		}

		.sn_articles_wrapper_grid, .sn_articles_wrapper_inspiration {
			margin-top: 0;
		}

	}
}
