.sn_range_filter {

	._block_filter{
		@include mq(0px, lg){
			justify-content: space-between;
		}

		.label{
			font-size: 12px;
			width: 70px;
			min-width: 70px;
			overflow-wrap: normal;

			@include mq(sm){
				width: 100px;
				min-width: 100px;
			}
		}
	}

	.noUi-target {
    background: #f3e9dd;
    border-radius: 4px;
    border: none;
	}

	.noUi-horizontal {
    height: 2px;
		max-width: 250px;
		width: 100%;
		margin: 10px 25px 0px;

		@include mq(sm){
			margin: 10px 60px 0px;
		}

	}


	.noUi-horizontal .noUi-handle {
	    width: 12px;
	    height: 12px;
	    right: 0px;
	    top: -5px;

	}

	.noUi-connect {
    background: #000000;
	}

	.noUi-handle{
		 border: 1px solid #000000;
	   border-radius: 50%;
	   background: #000000;
		 box-shadow: none;

		 &:before,
		 &:after{
			 display: none;
		 }
	}
}
