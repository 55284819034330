.sn_popup_info {
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	z-index: $zindex-sticky;
	transition: $transition-slow;
	opacity: 1;
	transform: none;
	pointer-events: auto;

	&._hide {
		opacity: 0;
		transform: translateY(100%);
		pointer-events: none;
	}

	&_close {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		transition: $transition-base;

		&:hover {
			color: $link-hover-color;
		}

		.sn_sprite {
			@include size(14px);
			margin-left: 10px;
			fill: currentColor;
		}


	}

	img {
		max-width: 200px;

		@include mq(md) {
			max-width: none;
		}
	}

	&_link {

		a {
			font-weight: fw(medium);
		}
	}
}
