.sn_filters {
	border-top: 1px solid $primary;
	display: flex;
	background-color: $secondary;
	position: fixed;
	left: 0;
	right: 0;
	z-index: $zindex-fixed;
	height: 100vh;
	//opacity: 0;
	top: -100vh;
	transition: $transition-smooth;
	flex-grow: 0;
	flex-direction: column;

	@include mq(lg) {
		transition: none;
		flex-direction: row;
		top: 0;
		opacity: 1;
		padding: 0;
		z-index: 0;
	}

	&._open {
		top: 0;
		//opacity: 1;
	}

	@include mq(lg) {
		position: relative;
		height: 84px;
	}

	&_show_mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 36px;
		@include font-size(13px);
		background: $secondary;
		border-top: 1px solid $primary;
		transition: $transition-base;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: $zindex-filters;
		opacity: 1;

		._open &{
			opacity: 0;
		}

		&:hover {
			cursor: pointer;
			background-color: $primary;
		}

		i {
			width: 9px;
			height: 9px;
			margin-left: 16px;
			transform: rotate(-90deg);
		}
	}

	&_close_mobile{
		display: flex;
		padding: 0 24px;
		justify-content: space-between;
		align-items: center;
		align-items: center;
		height: $header-height-xs;
		transition: $transition-base;

		&_logo{
			width: 90px;
			height: 20px;

			i{
				width: 90px;
				height: 20px;
			}
		}

		&_btn{
			display: flex;
			justify-content: flex-end;
			align-items: center;
			cursor: pointer;

			i{
				width: 13px;
				height: 13px;
				margin-top: 4px;
				margin-left: 10px;
			}
		}
	}

	&_wr {
		overflow-y: auto;
		display: flex;
		height: 100%;
		flex-direction: column;

		@include mq(lg){
			overflow-y: hidden;
			flex-direction: row;
			width: 100%;
		}
	}

	&_title,
	&_btn {
		width: percentage(1);
		height: auto;
		flex-shrink: 0;

		@include mq(lg){
			width: percentage(1/9);
		}

		@include mq(xl){
			width: percentage(1/6);
		}
	}

	&_title {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		@include font-size(30px, 20px);
		font-weight: fw(medium);
		color: $primary;
		border-right: none;
		padding: 40px 24px;

		@include mq(lg){
			padding: 0px;
			border-right: 1px solid $primary;
			justify-content: center;
		}

	}

	&_btn {
		width: auto;
		padding: 35px;
		margin: 24px;
		border: 0;
		background-color: $gray-200;
		@include font-size(13px);
		@include letter-spacing(.33px);
		font-weight: fw(medium);
		text-transform: uppercase;
		color: $dark;
		transition: $transition-base;

		@include mq(lg){
			margin: 0px;
			padding: 0px;
			width: percentage(1/9);
		}

		@include mq(xl){
			width: percentage(1/6);
		}

		&:hover {
			background-color: $primary;
		}
	}

	&_group {
		flex-grow: 0;
		display: flex;
		flex-direction: column;
		margin-bottom: 50px;

		@include mq(lg){
			flex-direction: row;
			margin-bottom: 0px;
			flex-grow: 1;
		}
	}

	&_i {
		width: percentage(1);
		flex-shrink: 0;
		position: relative;

		@include mq(lg){
			width: percentage(1/4);
		}

		select {
			display: none !important;
			opacity: 0 !important;
		}

		.tt {
			position: absolute;
			top: 25px;
			left: 30px;
			@include font-size(13px);
			text-transform: uppercase;
			line-height: 1;
			font-weight: fw(medium);
		}
	}
}
