.sn_slider_news{
  overflow: hidden;

  .swiper-container{
    overflow: visible;

    @include mq(lg){
      overflow: hidden;
    }
  }
}
