.sn_social_share {
	overflow: hidden;

	&_social {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include mq(0px, md) {
			flex-direction: column;
		}

		// &_cta {
		// white-space: nowrap;
		// }

		&_links {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			margin: 0 -8px 0px;

			&_i {
				margin: 0 8px 0px;
				padding: 10px 6px 21px;
				text-decoration: none;
				position: relative;
				display: flex;
				justify-content: center;

				&:hover {
					text-decoration: none;
					background: #f3e9dd;
					border-radius: 4px;
					color: #000 !important;

					&:before {
						opacity: 1;
					}
				}

				&:before {
					content: "•";
					position: absolute;
					bottom: -3px;
					left: 11px;
					color: #000;
					opacity: 0;
					transition: $transition-base;
				}
			}

			.sn_sprite {
				@include size(18px);
				fill: currentColor;
			}
		}
	}

	&_wishlist {
		appearance: none;
		border: none;
		background: none;
		margin: 0 8px 0px;
		padding: 10px 6px 6px;
		position: relative;
		cursor: pointer;
		&:hover {
			text-decoration: none;
			background: #f3e9dd;
			border-radius: 4px 4px 0px 0px;
			color: #000 !important;

			&:before {
				opacity: 1;
			}

			> span {
				opacity: 1;
			}
		}

		&:before {
			content: "";
			position: absolute;
			bottom: -3px;
			left: 11px;
			color: #000;
			opacity: 0;
			transition: $transition-base;
		}

		> span {
			display: block;
			position: absolute;
			top: 100%;
			left: 50%;
			translate: -50%;
			padding: 4px 6px;
			background: #f3e9dd;
			font-size: 13px;
			white-space: nowrap;
			border-radius: 4px;
			pointer-events: none;
			opacity: 0;
			transition: $transition-base;
		}
	}

	._mood_icon {
		width: 29px;
		margin-right: 16px;
		display: inline-block;

		i {
			margin-top: -6px;
		}
	}
}
