.select2.select2-container--default {
	width: 100% !important;
	height: 100%;
	border-right: none;
	box-sizing: content-box;

	@include mq(lg){
		border-right: 1px solid $primary;
	}

	&.select2-container--open {
		.select2-selection__arrow {
			transform: rotate3d(1, 0, 0, 180deg);
		}
	}

	.select2-selection {
		border-radius: 0;
		background-color: transparent;
		@include size(100%);
		border: 0;
		padding: 40px 30px 0;

		&__rendered,
		&__placeholder {
			@include font-size(14px);
			color: $gray-400 !important;
			padding-left: 0 !important;
		}

		&__rendered{
			border-bottom: 1px solid $primary;

			@include mq(lg){
				border-bottom: none;
			}
		}

		&__arrow {
			top: 40px;
			right: 30px;
			transform: none;
			transition: $transition-base;

			&::before {
				content: "";
				@include size(10px);
				background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='9' viewBox='0 0 500 500'><path fill='#{$black}' d='M499.7,154.3l-58.6-58.6L249.7,287.1L58.3,95.7l-58.6,58.6l191.4,191.4l0,0l58.6,58.6l0,0l0,0l58.6-58.6 l0,0L499.7,154.3z'/></svg>"));
				background-size: 100% 100%;
				background-position: center center;
				background-repeat: no-repeat;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -5px;
				margin-left: -5px;
			}

			b {
				display: none;
			}
		}

		&__clear {
			font-size: 14px;
			color: $black;
			position: absolute;
			bottom: 2px;
			right: 30px;

			@include mq(lg) {
				position: static;
			}
		}
	}
}

.select2-container {
	z-index: $zindex-fixed + 10;

	@include mq(lg) {
		z-index: 200;
	}

	.select2-dropdown {
		max-width: calc(100vw - 60px);
		margin-left: 30px;
		margin-right: 30px;
		background-color: $secondary;
		border-radius: 0;
		border-color: $primary;
		z-index: 100;

		@include mq(lg){
			max-width: none;
			margin-left: 0px;
			margin-right: 0px;
		}

		.select2-results {

			&__options {
				scrollbar-width: thin;
			  scrollbar-color: $gray-400 transparent;

				&::-webkit-scrollbar {
					width: 6px;
				}

				&::-webkit-scrollbar-track {
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $gray-400;
					border-radius: 20px;
				}
			}

			&__option {
				transition: $transition-base;
				padding: 6px 30px;
				@include font-size(14px);
				color: $gray-400;

				&--selected {
					color: $body-color !important;
					background: none;
				}

				&--highlighted {
					background-color: $primary;
					color: $gray-400;
				}
			}
		}
	}
}
