.sn_overlap_horizontal {

	&._border{
		border-top: 1px solid #e8e5e0;
	}

	&._is_home{
		h2{

			br{
				display: block;

				@include mq(lg){
					display: block !important;
				}
			}
		}
	}

	[data-animation-offset] {
		opacity: 1 !important;
		transition: $transition-animation-offset;
	}
	._image{
		height: auto;
		max-width: 32%;
	}

	._image_mobile {
		max-width: 500px;
	}

	&_title {
		margin-top: -40px;

		// @include mq(md) {
			// margin-top: pxvw(-170px);
		// }

		@include mq(lg) {
			margin-top: 117px;
		}

		h2{

			br{
				display: block;

				@include mq(lg){
					display: none;
				}
			}
		}
	}
}
