.sn_filters_switch {

	.filter {
		margin-left: 14px;
		margin-right: 14px;
		color: $gray-900;
		text-decoration: none;
		opacity: 0.4;
		cursor: pointer;
		transition: $transition-base;

		@include mq(md) {
			margin-left: 20px;
			margin-right: 20px;
		}

		&:hover {
			opacity: 1;
		}

		.text{
			opacity: 0;
			@include font-size(0px);
			transition: $transition-base;

			@include mq(md) {
				opacity: 1;
				@include font-size(16px);
			}
		}

		&._active {
			opacity: 1;

			.text {
				opacity: 1;
				@include font-size(14px, 16px, md);
			}
			.sn_sprite {
				margin-left: 10px;
			}
		}

		.sn_sprite {
			color: currentColor;
			@include size(17px);
			margin-top: 2px;
			margin-left: 0px;

			@include mq(md) {
				margin-left: 10px;
			}

			&._listing_panoramic , &._listing_list {
				@include size(23px);
				margin-top: 0;
			}
		}
	}
}
