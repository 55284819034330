.sn_breadcrumb {

	a,
	span {
		@include font-size(12px);
		@include letter-spacing(.6px);
		font-weight: fw(medium);
		color: inherit;
		text-decoration: none;
		text-transform: uppercase;

		&::before {
			content: '/';
			margin-right: 10px;
		}
	}

	&._multiple{
		a:first-child,
		span:first-child {

			&::before {
				content: '';
				margin-right: 0px;
				margin-left: 0px;
			}

		}

		a,
		span {

			&::before {
				content: '/';
				margin-left: 5px;
			}
		}
	}

	a {

		&:hover {
			opacity: .5;
		}
	}
}

.sn_paginator{
	position: relative;
	display: flex;
	z-index:100;
	width: 100%;
	text-align: left;
	bottom: 0;
	margin-top: 13px;
	justify-content: space-between;
	bottom: 0 !important;

	.pagination{
		list-style: none;
		padding: 0;
		display: flex;
		width: 100%;
		justify-content: space-between;

		@include mq(lg){
			display: flex;
			width: auto;
			justify-content: space-around;
		}

		.page-item{
			display: inline;

			.page-link{
				cursor: pointer;
				padding: 0px;
				font-size: 12px;
				line-height: 2.33;
				background: none !important;
				width: auto !important;
				height: auto !important;
				flex-grow: 1;
				color: #9f9f99;
				opacity: 1;
				text-decoration: none;


				@include mq(lg) {
					padding: 12px;
				}

				&:hover{
					opacity: .6;
				}

				&.active{
					position: relative;
					color: inherit;
					opacity: 1;

					&:after{
						content: " ";
						height: 1px;
						width: 96px;
						background: currentColor;
						margin: 5px 12px 5px 24px;
						display: inline-block;
						transition: all 0.5s;
						transform: rotateX(0deg);
					}
				}
			}
		}

	}
}

.sn_swiper_pagination {
	position: relative;
	display: flex;
	z-index:100;
	width: 100%;
	text-align: left;
	bottom: 0;
	margin-top: 13px;
	justify-content: space-between;
	max-width: 300px;
	bottom: 0 !important;

	@include mq(lg) {
		position: absolute;
		bottom: 40px !important;;
		text-align: right;
		margin-top: 0px;
		display: block;
		max-width: 100%;
	}

	&._news{
		@include mq(lg) {
			position: relative;
			bottom: 0px !important;
			text-align: left;
			margin-top: 0px;
			display: block;
			max-width: 100%;
		}
	}

	.swiper-pagination-bullet{
		cursor: pointer;
		padding: 0px;
		font-size: 12px;
		line-height: 2.33;
		background: none !important;
		width: auto !important;
		height: auto !important;
		flex-grow: 1;
		color: #9f9f99;
		opacity: 1;
		transition: $transition-base;

		@include mq(lg) {
			padding: 12px;
		}

		&:hover{
			opacity: .6;
		}


		&:after{
			content: " ";
			height: 1px;
			width: 0px;
			background: currentColor;
			margin: 5px 0px;
			display: inline-block;
			transition: all 0.5s;
			transform: rotateX(90deg);
		}

		&.swiper-pagination-bullet-active{
			position: relative;
			color: inherit;
			opacity: 1;

			&:after{
				content: " ";
				height: 1px;
				width: 96px;
				background: currentColor;
				margin: 5px 12px 5px 24px;
				display: inline-block;
				transition: all 0.5s;
				transform: rotateX(0deg);
			}
		}
	}
}

.sn_image_text {
  position: relative;
	display: block;
	overflow: hidden;
	height: 100%;

  figure {
    @include scut-ratio-box(577/746);
		background-size: cover;
		background-position: center center;
		transition: $transition-slow;
		min-height: 100%;

		&:hover{
			transform: scale(1.2);
		}
	}

	&:hover{
		figure {
			transform: scale(1.2);
		}
	}

  .content {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    color: $white;
		pointer-events: none;

		&._no_link{
			pointer-events: none;
		}

		._e_pointer{
			pointer-events: all;
		}

    @include mq(lg) {
			bottom: 30px;
	    left: 30px;
	    right: 30px;
    }

		@include mq(xl) {
			bottom: 60px;
	    left: 45px;
	    right: 45px;
    }

    .wrapper {
			display: block;
      height: 24px * 1.5 * 4;
      overflow: hidden;
			color: $white;

      @include mq(md) {
        height: 34px * 1.35 * 3;
      }

			@include mq(xl) {
        height: 34px * 1.35 * 4;
      }

      p {
        @include font-size(24px, 34px);
      	@include line-height(1.5, 1.35);
        @include letter-spacing(.45px, .85px);
      	font-weight: fw(regular);
      }
    }

		span{
			font-weight: fw(regular);

			strong{
				font-weight: fw(heavy);
			}
		}

  }
}

.sn_line {
	background-color: $gray-900;
	height: 2px;
	width: 100%;
}

.sn_text_content{

	p{
		margin-top: 50px;
	}

}
