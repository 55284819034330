.sn_slider_overlap_vertical {

	a {
		text-decoration: none;
	}

	[data-animation-offset] {
		opacity: 1 !important;
		transition: $transition-animation-offset;
	}

	&_title {
		margin-top: 40px;
		z-index: 10;

		@include mq(md) {
			margin-top: pxvw(-200px);
		}

		@include mq(1920px) {
			margin-top: -200px;
		}
	}

	p {

		& + img {
			margin-top: 40px;
			max-width: 100% !important;
			width: auto !important;
	 }
	}

 .btn {
		@include mq(md) {
			min-width: 275px;
		}
 }

 .sn_swiper_pagination {
		position: static;
		justify-self: end;
		margin-left: auto;
		text-align: left;
		display: block;
		max-width: 100%;
		padding-left: 25px;
		@include mq(md) {
			padding-left: 0;
			max-width: 30%;
			text-align: right;
		}

		@include mq(lg) {
			max-width: 20%;
		}
 }
}
