.sn_sprite {
	@include size(100%);
  display: inline-block;
	vertical-align: top;
	flex-shrink: 0;
	font-style: normal;

	svg {
		@include size(100%);
		display: inline-block;
		vertical-align: top;
		fill: inherit;
	}
}
