.sn_text_image_article {

	._big{
		p{
			@include font-size(24px);
			@include line-height (1.5);
		}
	}

	._small{
		p{
			@include font-size(12px);
			@include line-height (1.5);
		}
	}

	._caption{
		p{
			&::before {
				content: '/';
				margin-right: 10px;
				@include letter-spacing(.6px);
			}
		}
	}

}
