.sn_title_image {

	.title, .content {
		z-index: 5;
	}

	&_image {
		position: static;

		@include mq(lg) {
			position: absolute;
		}
	}

	.link {
		text-decoration: none;
		position: relative;

		&::before {
			content:"";
			position: absolute;
			background: currentColor;
			height: 3px;
			width: 100%;
			bottom:-2px;
		}
	}

	.image_container {
		position: static;
		padding-top: 0;

		@include mq(lg) {
			position: absolute;
			top:-30px;
			padding-top: inherit;
		}

		@include mq(xl) {
			top:-50px;
		}

		.image_mosaic {
			top: -50px;
			padding-top: inherit;
			right: $container-gutter;
			width: 100%;
		}
	}
}
