::selection {
	background: rgba($primary, .99);
  color: #000;
}
::-webkit-selection {
  background: rgba($primary, .99);
  color: #000;
}

body {

	&::before {
		content: '';
		display: none;
	}

	&._noscroll{
		overflow: hidden;
	}
}

html,body{
	scroll-padding-top: 80px;
}

a {
  transition: $transition-base;
}

.noscroll {
  overflow: hidden;
}

figure {
	margin: 0;
}

// Utils.mq() classes
.sn_utils_mq_xs { display: block;	@include mq(sm){ display: none }; }
.sn_utils_mq_sm { display: none; @include mq(sm){ display: block }; @include mq(md){ display: none }; }
.sn_utils_mq_md { display: none; @include mq(md){ display: block }; @include mq(lg){ display: none }; }
.sn_utils_mq_lg { display: none; @include mq(lg){ display: block }; @include mq(xl){ display: none }; }
.sn_utils_mq_xl { display: none; @include mq(xl){ display: block }; }


.container {
	padding-left: ($container-gutter / 2);
	padding-right: ($container-gutter / 2);

	@include mq(md) {
		padding-left: ($container-fluid-gutter / 2) + ($container-gutter / 2);
		padding-right: ($container-fluid-gutter / 2) + ($container-gutter / 2);
	}

	@include mq(xl) {
			padding-left: ($container-gutter / 2);
			padding-right: ($container-gutter / 2);
	}
}

.container-fluid {
	padding-left: ($container-gutter / 2);
	padding-right: ($container-gutter / 2);

	@include mq(lg) {
		padding-left: ($container-fluid-gutter / 2);
		padding-right: ($container-fluid-gutter / 2);
	}
}

.no-gutters {
	padding-left: 0;
	padding-right: 0;

	> .row {
		margin-right: 0;
		margin-left: 0;

		> .col,
		> [class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}

.no-gutters-mobile {

	@include mq(0px, sm-max) {
		padding-left: 0;
		padding-right: 0;

		> .row {
			margin-right: 0;
			margin-left: 0;

			> .col,
			> [class*="col-"] {
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}

.ipanorama{
	height: auto !important;
	aspect-ratio: 16 / 9;

	min-height: 250px;
	@include mq(md){
		min-height: 400px;
	}
	@include mq(lg){
		min-height: 650px;
	}
}
