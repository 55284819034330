@import './custom';

@import '../node_modules/plyr/src/sass/lib/css-vars';
$css-vars-use-native: false;

@import '../node_modules/plyr/src/sass/settings/breakpoints';
@import '../node_modules/plyr/src/sass/settings/colors';
@import '../node_modules/plyr/src/sass/settings/cosmetics';
@import '../node_modules/plyr/src/sass/settings/type';

@import '../node_modules/plyr/src/sass/settings/badges';
@import '../node_modules/plyr/src/sass/settings/captions';
@import '../node_modules/plyr/src/sass/settings/controls';
@import '../node_modules/plyr/src/sass/settings/helpers';
@import '../node_modules/plyr/src/sass/settings/menus';
@import '../node_modules/plyr/src/sass/settings/progress';
@import '../node_modules/plyr/src/sass/settings/sliders';
@import '../node_modules/plyr/src/sass/settings/tooltips';

@import '../node_modules/plyr/src/sass/lib/animation';
@import '../node_modules/plyr/src/sass/lib/functions';
@import '../node_modules/plyr/src/sass/lib/mixins';

@import '../node_modules/plyr/src/sass/base';

@import '../node_modules/plyr/src/sass/components/badges';
@import '../node_modules/plyr/src/sass/components/captions';
@import '../node_modules/plyr/src/sass/components/control';
@import '../node_modules/plyr/src/sass/components/controls';
@import '../node_modules/plyr/src/sass/components/menus';
@import '../node_modules/plyr/src/sass/components/sliders';
@import '../node_modules/plyr/src/sass/components/poster';
@import '../node_modules/plyr/src/sass/components/times';
@import '../node_modules/plyr/src/sass/components/tooltips';
@import '../node_modules/plyr/src/sass/components/progress';
@import '../node_modules/plyr/src/sass/components/volume';

@import '../node_modules/plyr/src/sass/types/audio';
@import '../node_modules/plyr/src/sass/types/video';

@import '../node_modules/plyr/src/sass/states/fullscreen';

@import '../node_modules/plyr/src/sass/plugins/ads';
@import '../node_modules/plyr/src/sass/plugins/preview-thumbnails/index';

@import '../node_modules/plyr/src/sass/utils/animation';
@import '../node_modules/plyr/src/sass/utils/hidden';

.plyr {

	&.plyr--stopped {

		@extend .plyr--hide-controls;
	}

	.plyr__controls {
		padding: 5px;
	}

	.plyr__control.plyr__control--overlaid {
		@include size(108px);
		margin-left: -54px;
		margin-top: -54px;
		background-color: transparent !important;
		border-radius: 0;
		background-size: 16px 16px !important;
		background-position: center center !important;
		background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='500' height='500' fill='#fff' x='0px' y='0px' viewBox='0 0 500 500' enable-background='new 0 0 500 500' xml:space='preserve'> <path fill-rule='evenodd' clip-rule='evenodd' d='M115.2,0.5c7,0,13.8,2,19.7,5.7l339.6,212.2c17.5,10.9,22.7,33.9,11.8,51.3 c-3,4.8-7,8.9-11.8,11.8L135,493.8c-17.4,10.9-40.4,5.6-51.3-11.8c-3.7-6-5.7-12.8-5.7-19.7V37.8C78,17.2,94.6,0.5,115.2,0.5z'/> </svg>")) !important;
		background-repeat: no-repeat !important;
		transform: scale(1);
		transition: $transition-base;
		border: 1px solid $white;
		border-radius: 50%;

		@include mq(md) {
			@include size(pxvw(144px));
			margin-left: pxvw(-72px);
			margin-top: pxvw(-72px);
			background-size: pxvw(22px) pxvw(22px) !important;
		}

		&:hover {
			// transform: scale(1.2);
		}

		svg, span {
			display: none;
		}
	}
}


// .plyr {
// 	height: 100%;

// 	&:hover {

// 		.plyr__control.plyr__control--overlaid {
// 			// transform: scale(1.2) !important;
// 		}
// 	}
// }

// .plyr__video-wrapper {
// 	height: 100%;
// 	padding-bottom: 0 !important;
// }
