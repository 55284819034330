.sn_footer {
	border-top: 1px solid  $gray-900;

	&._banner_padding{
		padding-bottom: $banner-height;
	}

	&_menu {
		display: flex;
		justify-content: space-between;
		margin-bottom: 45px;

		@include mq(md) {
			margin-bottom: 70px;
		}


		&_col {
			display: flex;
			flex-direction: column;

			@include mq(md) {
				width: 100%;
			}
			&:nth-child(n){
				padding-right: 20px;
			}

			&:last-child {
				padding-right: 0px;
				margin-bottom: 0px;
			}
		}

		&_i {
			display: inline-block;
			margin-bottom: 10px;

			@include mq(md) {
				margin-bottom: 40px;
			}

			&:last-child {
				margin-bottom: 0px;
			}

			a {
				text-decoration: none;
				@include font-size(14px, 20px);
			}
		}
	}

	&_newsletter {

		margin-bottom: 50px;

		@include mq(md) {
			margin-bottom: 70px;
		}

		.sn_form {

			._title {
				@include font-size(18px, 30px);
				margin-bottom: 15px;
				text-transform: uppercase;

				@include mq(md) {
					text-transform: none;
				}
			}

			._content {
				@include font-size(16px);
				margin-bottom: 40px;
				max-width: 430px;

				@include mq(md) {
					margin-bottom: 15px;
				}
			}

			&_row {
				display: flex;
				margin: 0px -12px;
				flex-direction: column;

				@include mq(md) {
					flex-direction: row;
				}
			}

			&_col {
				display: flex;
				padding: 0px 12px;

				&:first-child {
					flex-grow: 1;
				}
			}

			&_input {
				margin-bottom: 35px;
				padding-left: 0px;
				height: 100%;

				@include mq(md) {
					margin-bottom: 0px;
				}

				&:focus {
					border: 0;
					border-bottom: 1px solid $gray-900;
				}
			}

			.btn {
				white-space: nowrap;
				padding: 15px 30px 13px;
				min-width: 190px;
			}
		}
	}


	&_info {
		display: flex;

		@include mq(md) {
			margin-bottom: 80px;
		}

		&_block {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 100%;

			&:first-child {
				margin-right: 15px;
			}

			&:last-child {
				margin-left: 15px;
				@include mq(md) {
					margin-left: 0px;
				}
			}

			p {
				@include font-size(14px);
				line-height: 1.57;
			}

			img {
				align-self: flex-start;
				margin-bottom: 36px;
			}

			.sn_sprite {
				@include size(57px);
				align-self: flex-start;
				margin-bottom: 36px;
			}
		}
	}

	&_social {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
		margin: 60px 0px;

		@include mq(md) {
			flex-direction: row;
			margin-bottom: 80px;
			margin-top: 40px;
		}

		&_cta {
			margin-bottom: 15px;
			white-space: nowrap;
			margin-right: 30px;

			@include mq(md) {
				// margin-bottom: 0px;
			}
		}

		&_links {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -14px;

			@include mq(md) {
				margin: 0 -20px;
			}

			&_i {
				margin: 0 14px 10px;
				text-decoration: none;

				@include mq(md) {
					margin: 0 20px 10px;
				}

				&:hover {
					text-decoration: none;
				}
			}

			.sn_sprite {
				@include size(18px);
				fill: currentColor;
			}
		}
	}

	&_privacy_credits {
		display: flex;
		flex-direction: column;
		align-items: baseline;
		flex-wrap: wrap;
		@include mq(md) {
			flex-direction: row;
		}

		._privacy{
			display: block;
			padding: 0px;
			list-style-type: none;
			margin: 0px;
			word-break: break-word;
			margin-bottom: 35px;
			line-height: 22px;

			a, li {
				display: inline;
				text-decoration: none;
				@include font-size(14px);
			}

			li {

				&:first-child {
					font-weight: fw(bold);
				}

				&:nth-child(n){

					&::after {
						content: '-';
						margin-left: 8px;
						margin-right: 8px;
						font-weight: (normal);

					}
				}

				&:last-child {
					&::after {
						content: none;
						margin-right: 0px;
					}
				}
			}
		}

		._credits {
			position: relative;
			display: block;
			width: 100%;

			@include mq(md){
				text-align: right;
				margin-left: auto;
				width: auto;
			}

			.sn_sprite {
				@include size(20px);
				fill: currentColor;
				position: absolute;
				left: 0px;

				@include mq(md){
					right:0px;
					left: auto;
				}
			}

			a {
				@include font-size(14px);
				text-decoration: none;
				padding-left: 35px;

				@include mq(md) {
					padding-right: 35px;
					padding-left: 0px;
					margin-left: 20px;
				}

				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
