.sn_thematic_bands{
	overflow: hidden;
	&_i{
		&.--dark{
			background-color: #f3e9dd;
		}
	}
	.--text{
		display: flex;
		flex-direction: column;
	}

	.--gallery{
		padding:0 auto;

		.swiper-container {
			position: static;
			overflow: visible;
      .swiper-wrapper {

        .swiper-slide{
          width: auto;
          margin: 0 20px;
          @media (max-width: 1024px) {
            width: 80%;
            margin: 0 20px;
          }
          figure{
            height: 800px;
            overflow: hidden;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center, center;
            display: block;
            width: 100%;
            margin:0 1vw;
            @media (max-width: 1024px) {
              height: 550px;
              width: 100%!important;
            }
            &.--horizontal{
              aspect-ratio: 40/23;
              width: 98vw;
              @include mq(md) {
                width: 65vw;
              }
            }

            &.--vertical{
              aspect-ratio: 40/59.80;
              width: 37.8vw;
              @include mq(md) {
                width: 25vw;
              }
            }
          }
        }
      }
		}

		&-pagination{
			bottom:0 !important;
			margin:0 auto;
			width: fit-content;
			max-width: 100%;
			.swiper-pagination-bullet{
				cursor: pointer;
				padding: 0px;
				font-size: 12px;
				line-height: 2.33;
				background: none !important;
				width: auto !important;
				height: auto !important;
				flex-grow: 1;
				color: #9f9f99;
				opacity: 1;
				transition: $transition-base;

				@include mq(lg) {
					padding: 12px;
				}

				&:hover{
					opacity: .6;
				}

				&:after{
					content: " ";
					height: 1px;
					width: 0px;
					background: currentColor;
					margin: 5px 0px;
					display: inline-block;
					transition: all 0.5s;
					transform: rotateX(90deg);
				}

				&.swiper-pagination-bullet-active{
					position: relative;
					color: inherit;
					opacity: 1;

					&:after{
						content: " ";
						height: 1px;
						width: 96px;
						background: currentColor;
						margin: 5px 12px 5px 24px;
						display: inline-block;
						transition: all 0.5s;
						transform: rotateX(0deg);
					}
				}
			}
		}
	}
}
