/*
	Yoast Breadcrumb HTML:
	<nav id="breadcrumbs" class="main-breadcrumbs">
		<span>
			<span>
				<a href="#" >Homepage</a>
				<span class="separator"></span>
				<span class="breadcrumb_last" aria-current="page">Lorem ipsum</span>
			</span>
		</span>
	</nav>
*/

// .sn_breadcrumb {
// }
