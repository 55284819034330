.sn_discover {

	&_item {
		display: flex;
		flex-direction: column;

		h5, .h5 {
			font-weight: fw(regular);
		}
	}
}
