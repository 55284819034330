.sn_isotope_grid {

	&._grid_4{
		.grid-sizer{
			@include mq(sm){
				width: percentage(1/4);
			}
		}
		._space_size_1{
			@include mq(sm){
				width: percentage(1/4);
			}
		}

		._space_size_2{
			@include mq(sm){
				width: percentage(2/4);
			}
		}
	}

	.grid{
		margin-left: -($grid-gutter-width/2);
		margin-right: -($grid-gutter-width/2);

		@include mq(lg){
			margin-left: -($container-gutter/2);
			margin-right: -($container-gutter/2);
		}
	}

	.grid-sizer{
		width: percentage(1);

		@include mq(sm){
			width: percentage(1/3);
		}
	}

	.grid-item {
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;
		margin-bottom: $grid-gutter-width;

		@include mq(lg){
			padding-left: $container-gutter/2;
			padding-right: $container-gutter/2;
			margin-bottom: $container-gutter;
		}

		img{
			width: 100%;
		}
	}

	._space_size_1{
		width: percentage(1);

		@include mq(sm){
			width: percentage(1/3);
		}
	}

	._space_size_2{
		width: percentage(1);

		@include mq(sm){
			width: percentage(2/3);
		}

	}

	._space_size_3{
		width: percentage(1);
	}

}
