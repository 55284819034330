.sn_slider_column {

	.sn_slider_column_slider{
		overflow: visible;
	}

	._slider_center{

		@include mq(1100px){
			justify-content: center;
		}
	}
}
