.sn_articles{

	&_wrapper_inspiration, &_wrapper_grid{
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		width: 100%;
		margin-right: -25px;
    margin-left: -25px;
		width: 100%;
	}

	&_item{
		width: percentage(1);
		flex-shrink: 1;
		padding-right: 12px;
		padding-left: 12px;

		@include mq(md){
			width: percentage(1/2);
			padding-right: 25px;
    	padding-left: 25px;
		}

		@include mq(lg){
			width: percentage(1/2);
		}

		@include mq(xl){
			width: percentage(1/3);
		}

		&._big {
			@include mq(lg){
				width: percentage(1/2);
			}

			.sn_location{
				.location {
					margin-top: 80px;
				}
			}
		}

		.sn_location{

			._img_wrap{
				overflow: hidden;
				display: block;

				figure {
			    @include scut-ratio-box(577/324);
					background-size: cover;
					background-position: center center;
					transition: $transition-slow;

					&:hover{
						transform: scale(1.2);
					}
				}
			}


			.location{
				margin-top: 100px;

				p{
					@include font-size(12px);
				}
			}
		}


		.sn_product, .sn_collection{

			._img_wrap{
				overflow: hidden;
				display: block;

				figure {
			    @include scut-ratio-box(577/324);
					background-size: cover;
					background-position: center center;
					transition: $transition-slow;

					&:hover{
						transform: scale(1.2);
					}
				}
			}

		}

		.sn_page{
			border: 1px solid $gray-900;
			padding: 30px 25px;
			height: 100%;
			position: relative;

			@include mq(lg){
				padding: 60px 52px;
			}

			.title{
				@include font-size(24px);
				@include line-height(1.67);
				font-weight: fw(regular);

				@include mq(lg){
					@include font-size(56px);
					@include line-height(1.25);
				}
			}

			.sn_breadcrumb {
				position: relative;
				bottom: 0px;
				margin-top: 30px;

				@include mq(lg){
					position: absolute;
	    		bottom: 120px;
					margin-top: 0px;
				}

			}
		}

	}
}
