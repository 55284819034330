.sn_intro_parallax {

	@include mq(lg) {
		padding-bottom: 260px;
	}

	&_bg {
		padding-bottom: 75px;

		@include mq(lg) {
			padding-bottom: 0;
		}

		&::after {
			content: '';
			display:  table;
		}
	}

	&_title {
		margin-bottom: -60px;

		@include mq(lg) {
			height: 150px;
			margin-bottom: 0;
		}
	}

	&_wr {
		position: relative;
		z-index: 10;

		@include mq(lg) {
			margin-bottom: -260px;
		}
	}

	&_images {
		display: flex;
		justify-content: center;
		margin: 0 -25px;

		@include mq(lg) {
			padding-left: ($container-fluid-gutter / 2);
			padding-right: ($container-fluid-gutter / 2);
			justify-content: space-between;
		}

		figure {
			margin: 0 25px;
			flex-shrink: 0;

			&:first-child {
				margin-top: 140px;
				width: 200px;

				@include mq(lg) {
					margin-top: 330px;
					width: pxvw(326px);
				}
			}

			&:nth-child(2) {
				width: 265px;

				@include mq(lg) {
					width: pxvw(745px);
				}
			}

			&:last-child {
				margin-top: 300px;
				width: 200px;
				display: none;

				@include mq(md) {
					display: block;
				}

				@include mq(lg) {
					margin-top: 600px;
					width: pxvw(426px);
				}
			}
		}
	}

	&_content {
		width: 100%;
		z-index: 10;

		@include mq(lg) {
			position: absolute;
			top: 0;
			left: 0;
		}

		&_in {
			max-width: 570px;
			margin-left: auto;
      h1, h2 {
        color: #31312e;
      }
		}
	}
}
