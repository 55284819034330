.sn_intro_news{

	._date{
		@include font-size(12px);
	}

	._big{

		p{
			@include font-size(24px);
			@include line-height (1.5);
		}
	}
}
