.sn_header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	z-index: $zindex-fixed;
	height: $header-height-xs;
	padding-top: 17px;
	transition: $transition-smooth;

	@include mq($db) {
		padding-top: 0px;
		flex-direction: row;
		align-items: center;
		height: $header-height-db;
	}


	> div {
		._scroll_mobile{
			transition: $transition-smooth;
			transform: translateY(-100%);
			flex-grow: 1;

			@include mq($db) {
				transform: none;
				transition: none;
			}
		}
	}


	&._open {
		height: 100vh;
		background: $secondary;
		transition: none;

		@include mq($db){
			background: transparent;
			height: $header-height-db;
		}

		> div {
			height: 100%;

			._scroll_mobile{
				overflow-y: auto;
				overflow-x: hidden;
				// height: 100%;
				width: 100%;
				overflow-y: auto;
				margin: 30px 0;
				// padding-bottom: 30px;
				transform: none;
			}
		}


	}

	&._white{
		color: $white;

		&._open{
			color: $gray-900;
		}
	}

	&._black{
		color: $gray-900;
	}

	&._scroll{
		height: $header-height-scroll;
		color: $gray-900;
		background: $white;
		box-shadow: 0px 18px 15px -15px rgba(139, 133, 126, 0.35);

		&._open{
			height: 100vh;
			background: $secondary;
			transition: none;

			@include mq($db){
				background: transparent;
				height: $header-height-db;
			}
		}
	}

	&._submenu, &._search {
		background: $secondary;
		@include mq($db) {
			background: $white;
		}
	}

	._menu_grid{
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;

		@include mq($db){
			height: auto;
		}


		._left,
		._right{
			position: relative;

			@include mq($db){
				position: absolute;
				top:0;
			}

		}

		._left{
			left: 0;
		}

		._right{
			right: 0;
		}

		._center{
			display: flex;
			justify-content: flex-start;

			@include mq($db){
				justify-content: center;
			}

		}
	}


	&_logo {
		color: $gray-900;
		transition: $transition-base;

		@include mq($db) {
			margin-bottom: 0;
		}

		._white.sn_header & {
			color: $white;
		}

		._open._white.sn_header & {
			color: $gray-900;
		}


		._open & {
			transition: $transition-base;

			@include mq($db) {
				margin-bottom: 0;
			}
		}

		i{
			width: 90px;
			height: 19px;

			@include mq(md) {
				width: 116px;
				height: 25px;
			}

			svg {
				fill: currentColor;
			}
		}

	}

	&_menu {
		padding-left: 36px;
		margin-bottom: 15px;
		display: none;

		@include mq($db) {
			padding-left: 0px;
			margin-bottom: 0px;
			margin-top: 0px;
			display: block;
		}

		._open & {
			display: block;
			transition: $transition-base;
		}

		ul {
			list-style: none;
			display: flex;
			padding: 0px;
			flex-direction: column;
			margin-bottom: 0;

			@include mq($db) {
				flex-direction: row;
			}

			li {
				position: relative;
				margin: 10px 0;

				@include mq($db) {
					margin: 0px 10px;
					width: auto;
				}

				@include mq(xl) {
					margin: 0px 30px;
				}

				a {
					text-decoration: none;
					font-weight: fw(medium);
					text-transform: uppercase;
					letter-spacing: 0.4px;
					color: currentColor;
					transition: $transition-base;
					position: relative;
					padding-bottom: 2px;

					&::before {
						content:"";
						position: absolute;
						background: currentColor;
						height: 2px;
						width: 100%;
						bottom: 0;
						opacity: 0;
						transition: $transition-base;
					}

					&:hover {
						opacity: .5;

						&::before {
							opacity: 1;
						}
					}
				}

				&.current-menu-item > a::before {
					opacity: 1;
				}

				.sn_header_menu_in_children {
					visibility: hidden;
					overflow: visible;
					opacity: 0;
					height: 0;
					top: 0px;
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: flex-end;
					transition: $transition-smooth;
					margin-top: 0px;
					z-index: $zindex-fixed;

					@include mq($db) {
						overflow: hidden;
						margin-top: 0;
						position: fixed;
						top: $header-height-db;
				    left: 0;
				    right: 0;
				    background: #fff;
						flex-direction: row;
				    align-items: flex-end;
				    padding-bottom: 30px;
						box-shadow: 0px 18px 15px -15px rgba(139, 133, 126, 0.35);
					}

					._scroll &{
						top: 0;

						@include mq($db) {
							top: $header-height-scroll;
						}
					}

					._submenu &._submenu {
						visibility: visible;
						opacity: 1;
						height: 80px;
						margin-top: 20px;

						@include mq($db) {
							height: 385px;
							margin-top: 0px;
						}
					}

					._white & {
						background: transparent;
					}

					li{
						margin: 0px;

						@include mq($db) {
							margin: 0px 12px;
						}

						._title {
							@include font-size(14px, 18px, lg);
							margin-top: 0;
							margin-bottom: 15px;
							line-height: 1.2;

							@include mq($db) {
								margin-bottom: 22px;
								margin-top: 35px;
							}

						}

						._description {
							@include font-size(14px);
							font-weight: fw(regular);
							text-transform: none;
						}
					}

				}

				&.current-menu-item a::before {
					content:"";
					position: absolute;
					background: currentColor;
					height: 2px;
					width: 100%;
					bottom: -2px;

				}
			}
		}
	}

	&_secondary {
		display: none;
		padding-left: 36px;
		margin-bottom: 50px;

		@include mq($db) {
			display: block;
			padding-left: 0px;
			margin-bottom: 0px;
		}

		._open & {
			display: block;
			transition: $transition-base;
		}

		ul {
			list-style: none;
			display: flex;
			padding: 0px;
			margin: 0px;
			flex-direction: column;
			align-items: left;

			@include mq($db) {
				flex-direction: row;
				align-items: center;
			}

			li {
				position: relative;

				a {
					text-decoration: none;
					font-weight: fw(medium);
					letter-spacing: fw(normal);
					color: currentColor;

					&:hover {
						opacity: .5;
					}
				}

				&._search {

					margin: 20px 0;

					@include mq($db) {
						margin: 0 15px;
					}

					@include mq(xl) {
						margin: 0 47px;
					}

					._search_bar {
						border: none;
						background: transparent;
						font-size: 18px;
						width: 100%;

						&:focus,
						&:hover {
							outline: none;
						}
					}

					._search_wrap {
						text-decoration: none;
						font-weight: fw(medium);
						letter-spacing: fw(normal);
						vertical-align: middle;
						display: flex;
						align-items: center;

						i {
							width: 14px;
							height: 16px;
						}

						span {
							color: rgba(49, 49, 46, 0.5);
							margin-left: 18px;
						}
					}

					._search_toggle {
						height: 16px;
    				display: flex;
    				align-items: center;
						transition: $transition-base;

						&:hover {

							@include mq(lg) {
								opacity: .5;
							}
						}

						svg{
							fill: currentColor;
						}
					}

					._search_toggle_bar {
						visibility: hidden;
						overflow: visible;
						opacity: 0;
						height: 0;
						top: 0px;
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;
						transition: $transition-smooth;
						margin-top: 0px;
						z-index: $zindex-fixed;

						@include mq($db) {
							overflow: hidden;
							margin-top: 0;
							position: fixed;
							top: $header-height-db;
					    left: 0;
					    right: 0;
					    background: $secondary;
							flex-direction: row;
					    align-items: center;
							box-shadow: 14px 25px 52.44px 4.56px rgba(88, 83, 77, 0.11);
						}

						._scroll &{
							top: 0;

							@include mq($db) {
								top: $header-height-scroll;
							}
						}

						._search & {
							visibility: visible;
							opacity: 1;
							height: 120px;
							margin-top: 35px;

							@include mq($db) {
								height: 60px;
								margin-top: 0px;
							}
						}

						._search_bar {
							border: none;
							background: transparent;
							font-size: 22px;
							width: 100%;

							&:focus,
							&:hover {
								outline: none;
							}
						}

						._search_close{
							height: 15px;
							cursor: pointer;
						}

					}

				}

				.dropdown {

					&.show {

						i {
							transform: rotate3d(1,0,0,180deg);
						}
					}
				}

				.dropdown-toggle {
					@include font-size(16px);
					font-weight: fw(medium);
					letter-spacing: fw(normal);
					text-transform: none;
					text-decoration: none;
					padding: 0;
					text-align: left;
					height: auto;
					color: currentColor;
					display: block;
					float: left;
					width: 100%;
					transition: $transition-base;

					@include mq($db){
						float:none;
						width: auto;
						display: inline;
					}

					&:hover {
						opacity: .5;
					}


					&:after {
						content: none;
					}

					i {
						width: 12px;
						height: 12px;
						margin: 4px 0 0 6px;
						transform: none;
						transition: $transition-base;

						svg{
							fill: currentColor;
						}
					}
				}

				.dropdown-menu {
					left: auto;
					right: 0;
					padding: 0;
					border: 0;
					box-shadow: 0px 18px 15px -15px rgba(139, 133, 126, 0.35);
					min-width: 0;
					display: block !important;
					opacity: 0;
					transform: translateY(-20px);
					transition: $transition-base;
					pointer-events: none;
					position: relative;
					margin-left: 30px;
					margin-top: 10px;
					background: transparent;
 					z-index: $zindex-fixed + 10;

					@include mq($db){
						position: absolute;
						margin-left: 0px;
						background: $white;
					}


					&.show {
						opacity: 1;
						transform: none;
						pointer-events: auto;
					}

					.dropdown-item {
						background: transparent;
						padding: 0px;
						// display: inline !important;
						transition: $transition-base;

						@include mq($db){
							padding: 14px 30px;
							background: $white;
						}
					}
				}

				&.current-menu-item a::before {
					content:"";
					position: absolute;
					background: currentColor;
					height: 2px;
					width: 100%;
					bottom:4px;

				}
			}
		}

	}

	&_mobile {
		position: absolute;
		display: flex;
		align-items: center;
		right: 12px;
		top: 3px;

		@include mq($db) {
			display: none;
		}

		&_close {
			cursor: pointer;
			visibility: hidden;
			opacity: 0;
			align-items: center;
			transition: $transition-base;
			color: currentColor;
			@include font-size(16px);
			margin-bottom: -8px;
			display: flex;
			align-items: center;
			margin-top: -5px;

			._open &{
				visibility: visible;
				opacity: 1;
				transition: $transition-base;
			}

			&:hover {
				//color: $link-hover-color;
			}

		}

		.hamburger {
			margin-left: 20px;
			//color: $white;
			@include hamburger($width: 24px, $height: 15px, $bar-height: 3px);
			transition: $transition-base;

			._white &{
				color: $white;

				._open &{
					color: $gray-900;
				}
			}

			&:hover,
			&:active {
				> span {
					background: currentColor;
				}
			}

			> span {
				background: currentColor;
			}
		}

		&_search {
			height: 15px;
			width: 15px;
			opacity: 1;
			visibility: visible;
			cursor: pointer;

			._white & {
				color: $white;
			}

			._open & {
				width: 0;
				height: 0;
				transition: $transition-base;
				opacity: 0;
				visibility: hidden;
			}

			svg{
				fill: currentColor;
			}

			.search_mobile_bar{
				position: fixed;
				visibility: hidden;
				left: 0;
				right: 0;
				top: $header-height-scroll;
				height: 0;
				background: $secondary;
				padding: 12px;
				box-shadow: 0px 18px 15px -15px rgba(139, 133, 126, 0.35);
				transition: $transition-smooth;
				opacity: 0;

				._search_mobile & {
					top: $header-height-scroll;
					opacity: 1;
					height: 50px;
					visibility: visible;
				}

				._search_bar {
					border: none;
					background: transparent;
					font-size: 18px;
					width: 100%;

					&:focus,
					&:hover {
						outline: none;
					}
				}

				._search_close_mobile{
					height: 15px;
					width: 15px;
					cursor: pointer;
				}
			}
		}
	}

	._cursor_pointer{
		cursor: pointer;
	}
}
