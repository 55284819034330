.sn_map {

	.gmap{
		@include scut-ratio-box;
		box-shadow: 14px 25px 52.44px 4.56px rgba(88, 83, 77, 0.11);

		@include mq(sm){
			@include scut-ratio-box(3/2);
		}

		@include mq(lg){
			@include scut-ratio-box(772/363);
		}

		.gm-style-iw-d{
			text-align: left;
			max-width: 500px;
		}
	}

	&_store{
		border-bottom: 1px solid $gray-800;
		padding: 44px 0px 30px;

		.links {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;

			a {
				font-weight: fw(medium);
				text-decoration: none !important;
				position: relative;
				display: inline-block;
				margin: 0 15px 10px;
				line-height: 1;
				padding-bottom: 6px;

				&::before {
					content: "";
					position: absolute;
					background: currentColor;
					height: 3px;
					width: 100%;
					bottom: 0;
					left: 0;
				}
			}
		}
	}

	._wrap_legend{
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;

		@include mq(sm){
			flex-direction: row;
			justify-content: flex-end;
		}

		._legend{
			margin-right: 30px;
			display: flex;
			align-items: center;

			._label{
				display: inline-block;
				margin-right: 10px;
			}

			._icon{
				width: 25px;
				height: 33px;
				display: inline-block;

				&._pin_small{
					width: 18px;
					height: 23px;
				}
			}
		}
	}


	.filters-button-group{
		display: flex;
		flex-direction: column;

		@include mq(md){
			flex-direction: row;
		}

		a{
			border: 2px solid $white;
			border-radius: 50px;
			color: $gray-900;
			background: transparent;
			padding: 10px 20px;
			text-decoration: none;
			cursor: pointer;


			@include mq(0px,sm){
				width: 100%;
				flex-direction: column;
			}

			@include mq(md){
				padding: 10px 48px 8px;
				flex-direction: row;
			}

			&.is-checked{
				border: 2px solid $secondary;
				border-radius: 50px;
				margin: 0;
			}
		}
	}

	.gm-ui-hover-effect{
		top: 0 !important;
		right: 0 !important;
	}
}
