.sn_intro_breadcrumb{

	._big{
		p{
			@include font-size(34px);
			@include line-height (1.5);
		}
	}

	._small{
		p{
			@include font-size(12px);
			@include line-height (1.5);
		}
	}
}
