.sn_details {
	h6 {
		letter-spacing: normal;
	}
	&_title {
		color: $gray-900;
	}

	&_materials {
		display: none;
		@include mq(lg) {
			display: grid;
		}
		grid-template-columns: repeat(3,1fr);
		grid-gap: 50px 20px;
		img {
			width: 100%;
		}
	}

	&_big {
		.image_big {
			@include scut-ratio-box(442/660);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center, center;
			width: 100%;

			@include mq(lg) {
				max-width: 450px;
			}
		}
	}

	&_content {


		&_title {
			@include font-size(13px);
			letter-spacing: 0.65px;
			font-weight: fw(medium);
		}

		._small {
			p{
				@include font-size(14px);
				@include line-height(1.71);
			}
		}
	}

	&_materials, &_material_slider {
		overflow: visible;
		.sn_item_img {
			@include scut-ratio-box(10/10);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center, center;
			display: block;
			width: 100%;
		}
	}

	&_products_slider{

		.sn_item_img{
			@include scut-ratio-box(517/345);
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center, center;
			display: block;
			width: 100%;
		}

		.sn_swiper_pagination {
			position: static;
		}
	}
}
