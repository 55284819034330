.sn_text_image_composition {
	position: relative;

	.sn_item_in_img {
		width:100%;
		@include scut-ratio-box(328 / 491);
	}

	&_image_big {
		position: absolute;
		left: 0;
		top: 0;
	}

	._img_big {
		max-width: 50vw;
	}
	.swiper-container {
		overflow: visible;
	}

	.sn_swiper_pagination {
    position: relative;
    z-index:100;
    width: 100%;
    text-align: left;
    bottom: 0;
    margin-top: 13px;

    @include mq(lg) {
			position: absolute;
      bottom: 40px;
      text-align: right;
      margin-top: 0px;
		}

    .swiper-pagination-bullet{
      cursor: pointer;
      padding: 12px;
      font-size: 12px;
      line-height: 2.33;
      background: none !important;
      width: auto !important;
      height: auto !important;
      opacity: 0.8;

      &:after{
        content: " ";
        height: 1px;
        width: 0px;
        background: currentColor;
        margin: 5px 0px;
        display: inline-block;
        transition: all 0.5s;
        transform: rotateX(90deg);
      }

      &.swiper-pagination-bullet-active{
        position: relative;
        opacity: 1;

        &:after{
          content: " ";
          height: 1px;
          width: 96px;
          background: currentColor;
          margin: 5px 12px 5px 24px;
          display: inline-block;
          transition: all 0.5s;
          transform: rotateX(0deg);
        }
      }
    }
  }
}
